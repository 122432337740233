import { Button, Icon, IconButton, Input, MenuItem, Select, Switch, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { Dispatch, FunctionComponent, SetStateAction, useCallback } from 'react';

export interface params {
  name: string;
  type: string;
  mandatory: boolean;
  default: string;
}

interface EditorParametersProps {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
}

const useStyles = makeStyles({
  inputs: {
    marginRight: 10,
  },
  columns: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  params: {
    marginBottom: 20,
  },
});

export const EditorParameters: FunctionComponent<EditorParametersProps> = ({ value, setValue }) => {
  const classes = useStyles();

  const addParams = useCallback(() => {
    const newParams = JSON.parse(value);
    newParams.push({ name: '', type: 'string', mandatory: false, default: '' });

    setValue(JSON.stringify(newParams));
  }, [value, setValue]);

  const handleChangeParams = (key: string, index: number, paramsValue: string | boolean) => {
    const newParams = JSON.parse(value);

    newParams[index][key] = paramsValue;

    setValue(JSON.stringify(newParams, null, 2));
  };

  const handleDelete = useCallback(
    (index: number) => {
      const newParams = JSON.parse(value);

      newParams.splice(index, 1);

      setValue(JSON.stringify(newParams, null, 2));
    },
    [value, setValue],
  );

  return (
    <>
      <div className={classes.columns}>
        {JSON.parse(value).map((params: params, index: number) => (
          <div className={classes.params}>
            <TextField onChange={(event) => handleChangeParams('name', index, event.target.value)} variant="standard" className={classes.inputs} size="small" InputLabelProps={{ shrink: true }} label="Name" value={params.name} />
            <Select
              value={params.type}
              onChange={(event) => handleChangeParams('type', index, event.target.value)}
              variant="standard"
              size="small"
              label="Type"
              aria-label="Type"
              MenuProps={{
                style: { zIndex: 2500 },
              }}
            >
              <MenuItem value="string">String</MenuItem>
              <MenuItem value="number">Number</MenuItem>
              <MenuItem value="date">Date</MenuItem>
              <MenuItem value="array">Array</MenuItem>
              <MenuItem value="boolean">Boolean</MenuItem>
              <MenuItem value="object">Object</MenuItem>
            </Select>
            <Switch checked={params.mandatory} onChange={(event) => handleChangeParams('mandatory', index, event.target.checked)} />
            <TextField onChange={(event) => handleChangeParams('default', index, event.target.value)} variant="standard" className={classes.inputs} size="small" InputLabelProps={{ shrink: true }} label="Default" value={params.default} />
            <IconButton onClick={() => handleDelete(index)}>
              <Icon>clear</Icon>
            </IconButton>
          </div>
        ))}
        <Button variant="contained" onClick={addParams}>
          Agregar Parametros
        </Button>
      </div>
    </>
  );
};
