import {useMemo} from "react";
import {AnyObject} from "../types";

export const useRowsFromAttributes = (attributes: Set<any>, values: AnyObject) => useMemo(() => {
  const _rows: AnyObject[] = [];
  attributes.forEach((attribute: any) => {
    _rows.push({
      id: `${attribute.name}`,
      prop: attribute.name,
      value: values[attribute.name] !== undefined ? values[attribute.name] : attribute.default,
      type: attribute.type,
      list: attribute.list,
      label: attribute.label,
      metadata: attribute.metadata,
    });
  });
  return _rows;
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [values]);
